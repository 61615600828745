import React, { useEffect, useState } from "react";
import {  Box, Button, Container, Grid, Popover, Typography} from "@mui/material";
import PageTitle from "../common/PageTitle";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { LIST_LIMIT } from "../../constants/constants";
import RoleManagementService from "./services/roleManagementService";
import Pagination from "../common/Pagination";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CreateUserRole from "./CreateUserRole";
import Skeleton from "../common/Skeleton";

const RoleManagementSystem = (props: any) => {

    const { showSnackbar ,handleLoader} = props;

    const firstPage = 1;
    const[t] = useTranslation();

    const [limit, setLimit] = useState(LIST_LIMIT.PAGINATION_LIST_LIMIT);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [anchorElPopover, setAnchorElPopover] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [skeleton, setSkeleton] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [createUserRole,setCreateUserRole]= useState<boolean>(false);
    const [rmsData,setRmsData]=useState<any>();
    const [roleName,setRoleName]=useState<string>("");
    const [roleNameId,setRoleNameId]=useState<number>(0);

    const apiData: any[][] = [];

    useEffect(()=>{
        roleManagementSystemAPICall(firstPage, limit);  
    },[]);

    /**
 *
 * @param currentPage - requesting current page number for pagination
 * @param limit - requesting page limit for pagination
 * @returns - returns API response for rms table
 * @description - function to request role management get API 
 */
    const roleManagementSystemAPICall = (currentPage: number, limit: any) => { 
        setSkeleton(true);     
        const payload = {};
        const isHitDummyURL = false;
        new RoleManagementService(payload, isHitDummyURL)
            .roleManagementList(currentPage, limit)
            .then((res) => {  
                setRmsData(res?.roleMangementLogs);
                setTotalCount(res.pagination.totalCount);
                setCurrentPage(currentPage);
                setTotalPages(res.pagination.totalPages); 
                setSkeleton(false);             
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    const columns = [
        {
            name:  t("role_management_system.s_no"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("role_management_system.role_name"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("role_management_system.user_count"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("role_management_system.action"),
            options: {
                filter: false,
                sort: false,
            },
        },     
       
    ];

    /**
 * @param roleName - requesting  roleName for managing user role
 * @param id - requesting  id for managing user role
 * @description - open manage user role page to edit selected role name permissions
 */
    const handleUserRole = (roleName:any,id:any)=>{
        setRoleNameId(id);
        setRoleName(roleName);
        setCreateUserRole(true);
    };

    /**
 * @param roleNameId - requesting  roleNameId for deleting selected user role
 * @returns - returns API response for deleting user role
 * @description -  function to request delete user role API 
 */
    const handleDelete = (roleNameId:any) => {
        // handleLoader(true);
        const isHitDummyURL = false;
        new RoleManagementService({}, isHitDummyURL)
            .deleteUserRole(roleNameId)
            .then((res) => {
                showSnackbar(res, true);
                // handleLoader(false);
                roleManagementSystemAPICall(firstPage, limit);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });

    };

    const actionButton = (
        name: string, 
        description:string, 
        id:number, 
        count:number  
    ) => {
        return (
            <>
                <Box className="table_btn">
                    <Button
                        className="table_btn"
                        variant="contained"
                        onClick={() => handleUserRole(name,id)}
                        disabled={description == "DEFAULT_ADMIN" ? true : false}
                    >
                        {t("role_management_system.edit")}
                    </Button>
                   
                    <Button
                        className="table_btn"
                        variant="contained"
                        onClick={() => handleDelete(id)}
                        disabled={count != 0 || description == "DEFAULT_ADMIN"}
                    >
                        {t("role_management_system.delete")}
                    </Button>
                </Box>
            </>
        );
    };

    const apiTableData = () => {
        {rmsData?.map((item: any, indexItem: number) => {
            apiData[indexItem] = [];
            apiData[indexItem].push(
                (currentPage - 1) * limit + indexItem + 1,
                item?.name,
                item?.userCount,
                actionButton(item?.name , item?.description , item?.id , item?.userCount)

            );
        });
        }
    };
    apiTableData();

    const handleInsPopoverOpen = (event:any) => {
        setAnchorElPopover(event.currentTarget);
    };
    
    const handleInsPopoverClose = () => {
        setAnchorElPopover(null);
    };


    return (

        <>
            { createUserRole ? <CreateUserRole roleName={roleName} roleNameId={roleNameId} showSnackbar={showSnackbar} handleLoader={handleLoader}/>:
                (
                    <>
                        <Popover
                            open={Boolean(anchorElPopover)}
                            anchorEl={anchorElPopover}
                            onClose={handleInsPopoverClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            className="instruction_popover"
                
                        >
                            <Box sx={{padding:2}}>
                                <Typography className="rms_rules_headlines"> {t("role_management_system.create_role_details")}</Typography>
                                {/* <Typography className="rms_rules_notes">{t("role_management_system.rms_instruction_1")}</Typography> */}
                                <Typography>{t("role_management_system.rms_instruction_2")}</Typography>
                                <Typography>{t("role_management_system.rms_instruction_3")}</Typography>
                                <Typography>{t("role_management_system.rms_instruction_4")}</Typography>
                                {/* <Typography>{t("role_management_system.rms_instruction_5")}</Typography> */}
                            </Box>
                        </Popover>
                        <Container>
                            <Grid container spacing={0} sx={{ py: 5 }}>
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ py: 2 }}>
                                    <PageTitle title={t("role_management_system.role_management_system")}/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ py: 6 }}>
                       
                                    <Button
                                        className="float_right"
                                        size="small"
                                        sx={{ py: 2 }}
                                        startIcon={<AddCircleIcon />}
                                        onClick={()=>handleUserRole("",0)}
                                    >
                                        {t("role_management_system.add_role")}
                                    </Button>
                                    <InfoRoundedIcon
                                        onClick={handleInsPopoverOpen}
                                        color="primary"
                                        className="rms_instruction_icon float_right"
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            <Grid container spacing={2}>
                                <Grid item   xs={12} sm={12} md={12} lg={8} xl={6}>
                                    {skeleton ? (
                                        <Skeleton
                                            varient="rectangular"
                                            width={0}
                                            height={60}
                                            count="10"
                                            my={0}
                                        />
                                    ) : (
                                        <>
                                            <Container className="minimum_column first_table_cell_small_width rms_table">
                                                <MUIDataTable 
                                                    title={""}
                                                    columns={columns}
                                                    data={apiData}
                                                    options={{
                                                        pagination: false,
                                                        print: false,
                                                        download: false,
                                                        filter: false,
                                                        search: false,
                                                        viewColumns:false,
                                                        selectableRowsHideCheckboxes: true,                              
                                                        textLabels: {
                                                            body: {
                                                                noMatch: t("table_text.no_match").toString(),
                                                            },
                                                            viewColumns: {
                                                                title: t("table_text.show_columns").toString(),
                                                                titleAria: "Show/Hide Table Columns",
                                                            },
                                                            toolbar: {
                                                                search: t("table_text.search").toString(),
                                                                viewColumns: t("table_text.view_columns").toString(),
                                                            },
                                                        },
                                                        searchPlaceholder: t("table_text.search_placeholder").toString(),
                                                        responsive: "standard",                                       
                                                    }}
                                   
                                                />
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    pageChange={roleManagementSystemAPICall}
                                                    totalPage={totalPages}
                                                    rowsPerPage={limit}
                                                    rowsPerPageChange={setLimit}
                                                />
                                            </Container> 
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={0} sm={0} md={0} lg={0} xl={1} className="padding-0"></Grid>
                                <Grid item xs={0} sm={0} md={0} lg={4} xl={4}  className="image_upload_box_container"  >
                                    <Grid className="rms_img_box">
                                        <img className="rms_img" alt="cainz" src="./assets/images/roleManagementSystem.png"/>
                                        <Box className="rms_rules_box">
                                            <Typography className="rms_rules_headlines"> {t("role_management_system.create_role_details")} </Typography>
                                            {/* <Typography className="rms_rules_notes">{t("role_management_system.rms_instruction_1")}</Typography> */}
                                            <Typography>{t("role_management_system.rms_instruction_2")}</Typography>
                                            <Typography>{t("role_management_system.rms_instruction_3")}</Typography>
                                            <Typography>{t("role_management_system.rms_instruction_4")}</Typography>
                                            {/* <Typography>{t("role_management_system.rms_instruction_5")}</Typography> */}

                                        </Box>
                                    </Grid>                        
                                </Grid>
                                <Grid item xs={0} sm={0} md={0} lg={0} xl={1}></Grid>
                            </Grid>              
                        </Container>
                    </>
                )
            }
        </>
    );
};

export default RoleManagementSystem;